<template>
  <div>
    <SubNav main-route="meta" section="indicator" title="Create Indicator(s)" />
    <div class="container-fluid pb-1">
      <Loader v-if="applyingChecks" />
      <div v-else class="mb-sm-4 mx-3 my-4 row">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-4">
              <div class="col">
                <h5 class="h5 text-muted font-weight-bold">Preview/Edit Data</h5>
                <p>Your data has been checked for consistency. It's good to go.</p>
              </div>
              <div>
                <b-dropdown
                  id="dropdown-1"
                  text="Share this Page"
                  class="shadow float-right"
                  variant="primary"
                  block
                >
                  <b-dropdown-item>Share via Link</b-dropdown-item>
                  <b-dropdown-item>Share to user </b-dropdown-item>
                </b-dropdown>
                <button class="btn mr-2 float-right btn-warning shadow">Advance Filters</button>
              </div>
            </div>

            <div class="row pt-3 px-3 py-3">
              <div class="col-lg-6 mb-1">
                <span>Showing all {{ total }} records</span>
              </div>
              <div v-for="entry in filters" :key="entry.name" class="col-lg-2 mb-1">
                <div class="row">
                  <select class="form-control mr-2">
                    <option disabled selected>{{ entry.label }}</option>
                    <option
                      v-for="option in entry.options"
                      :key="option.value"
                      :value="option.value"
                      :selected="option.selected"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2 mb-1">
                <button action-btn class="btn btn-block btn-warning">
                  Delete <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                </button>
              </div>
            </div>
            <TableComponent
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :fields="fields"
              bordered
              :data="items"
              @selected="handleSelection"
            />

            <div class="row mb-3 p-4 pt-3 px-3 py-3">
              <div class="col-lg-12">
                <button class="btn mr-2 action-btn btn-danger action-btn shadow">Cancel</button>
                <button class="btn mr-2 action-btn btn-primary action-btn shadow">Save</button>
                <button class="btn mr-2 action-btn float-right action-btn btn-success shadow">
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from "../../../components/NavigationSubNav.vue";
import Loader from "../../../components/Loader.vue";
import TableComponent from "../../../components/Table.vue";
export default {
  name: "Blank",
  components: {
    SubNav,
    Loader,
    TableComponent,
  },
  data() {
    return {
      name: "PreviewIndicator",
      applyingChecks: true,
      sortBy: "name",
      sortDesc: false,
      fields: [
        "select",
        { key: "name", label: "Generalized name", sortable: true },
        { key: "area", label: "Program area", sortable: true },
        { key: "type", label: "Indicator type", sortable: true },
        { key: "target", label: "National Target", sortable: true },
        { key: "slope", label: "Slope", sortable: true },
      ],
      filters: [
        {
          name: "indicator",
          label: "Select Indicator",
          options: [{ value: "skilled birth attendance", name: "skilled birth attendance" }],
        },
        { name: "area", label: "Program area", options: [] },
      ],
    };
  },
  computed: {
    items() {
      let sample = {
        name: "Skilled birth attendance",
        area: "Maternal and child health",
        type: "Input",
        target: "10%",
        slope: "Positive",
      };
      return Array(25).fill(sample);
    },
    total() {
      return this.items.length;
    },
  },
  created() {
    setTimeout(() => (this.applyingChecks = false), 2000);
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    handleSelection(rows) {
      console.log(rows);
    },
  },
};
</script>

<style lang="scss" scoped></style>
