<template>
  <SubNav :title="title">
    <div v-if="subItems" class="row m-3">
      <div v-for="(route, index) in subItems" :key="route.path" class="col-md-2">
        <!-- <AppLink :to="'#'"> -->
        <div class="tab text-center" :class="[route.path === $route.path ? 'active' : '']">
          <small class="tab__badge text-white">{{ index + 1 }}</small>
          <span>{{ route.title }}</span>
        </div>
        <!-- </AppLink> -->
      </div>
    </div>
  </SubNav>
</template>

<script>
import AppLink from "./layout/components/Sidebar/Link.vue";
import NavigationMixin from "./layout/mixins/navigation";
import { EVENT_BUS } from "../store/bus";
import router from "../router";
import path from "path";
import SubNav from "./SubNav.vue";

export default {
  name: "NavigationSubNav",
  // eslint-disable-next-line vue/no-unused-components
  components: { AppLink, SubNav },
  mixins: [NavigationMixin],

  props: {
    /**
     *
     */
    title: {
      type: String,
      default: "",
    },
    /**
     *
     */
    mainRoute: {
      type: String,
      default: "",
    },
    /**
     *
     */
    section: {
      type: String,
      default: "",
    },
  },
  computed: {
    routes() {
      return this.$store.state.permission.allRoutes;
    },
    basePath() {
      return this.mainRoute;
    },
    /**
     * @typedef {Object} SubNav
     * @property {String} link
     * @property {String} name
     *
     * @returns {Array<SubNav>}
     */
    subItems() {
      let parentRoute = this.routes.find((x) => x.path.includes(this.mainRoute));
      if (parentRoute) {
        let children;
        if (this.section.length) {
          children = parentRoute.children.filter((x) => x.path.includes(this.section));
        } else children = parentRoute.children;

        return children
          .filter((x) => x.subNav == true)
          .map((x) => {
            return {
              path: this.resolvePath(`${this.basePath}/${x.path}`),
              title: x.meta.navTitle || x.meta.title,
            };
          });
      }
      return [];
    },
    /**
     * @returns {Number}
     */
    active() {
      if (this.subItems.length === 0) return -1;
      for (let i = 0; i < this.subItems.length; i++) {
        if (this.resolvePath(this.subItems[i].path) === this.$route.path) return i;
      }
      return -1;
    },
    /**
     * @returns {Number}
     */
    next() {
      if (this.active == -1) return null;
      return this.subItems[this.active + 1];
    },
    /**
     * @returns {Number}
     */
    previous() {
      if (this.active == -1) return null;
      return this.subItems[this.active - 1];
    },
  },
  created: function () {
    EVENT_BUS.$on("SUB_NAV", async (direction) => {
      try {
        if (!this.next && !this.previous) return;
        if (direction === "next" && this.next) await router.push(this.next.path);
        if (direction === "previous" && this.previous) await router.push(this.previous.path);
      } catch (error) {
        console.log(error);
      }
    });
  },
  methods: {
    /**
     * @param {String} routePath
     *
     * @returns {String} Normalized route path
     */
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }
      return path.resolve("", routePath);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #c7cad0;
}
.active span {
  text-decoration: underline;
}
.tab {
  width: 210px;
}
</style>
