<template>
  <div class="container-fluid pb-1 sticky-top sub-nav">
    <div class="row mx-3 my-4">
      <div class="col-md-12">
        <slot name="title" :title="title">
          <h4 class="h4 text-muted font-weight-bold">
            {{ title }}
          </h4>
        </slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SubNav",
  props: {
    /**
     *
     */
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped></style>
